import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ManageView from '../views/ManageView.vue'
import ManageNodeView from '../views/ManageNodeView.vue'
import ManageAccountView from '../views/ManageAccountView.vue'
import ManageValidatorView from "../views/ManageValidatorView.vue";
import ManageHomeView from "@/views/ManageHomeView.vue";
import ManageConfigView from "../views/ManageConfigView.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/manage',
    name: 'manage',
    component: ManageView,
    children: [
      {
        path: '',
        name: '',
        component: ManageHomeView
      },
      {
        path: 'node',
        name: 'manage-node',
        component: ManageNodeView
      },
      {
        path: 'account',
        name: 'manage-account',
        component: ManageAccountView
      },
      {
        path: 'validator',
        name: 'manage-validator',
        component: ManageValidatorView
      },
      {
        path: 'config',
        name: 'manage-config',
        component: ManageConfigView
      },
    ],
  },
]

const router = new VueRouter({
  routes
})

export default router
