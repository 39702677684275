import { render, staticRenderFns } from "./ManageAccountView.vue?vue&type=template&id=3722381d&scoped=true&"
import script from "./ManageAccountView.vue?vue&type=script&lang=ts&"
export * from "./ManageAccountView.vue?vue&type=script&lang=ts&"
import style0 from "./ManageAccountView.vue?vue&type=style&index=0&id=3722381d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3722381d",
  null
  
)

export default component.exports