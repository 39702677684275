import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userName: "",
    userWalletAddress: "",
    userToken: "",
    userRole: "",
    network: "",
  },
  getters: {
    endpointHost(state) {
      switch (state.network) {
        case "mainnet":
          return "https://api.watchdog.t.hmny.io/"+state.network
        case "testnet":
          return "https://api.watchdog.b.hmny.io/"+state.network
        case "devnet":
          return "https://api.watchdog.b.hmny.io/"+state.network
        case "localnet":
          return "http://localhost:8088"
        default:
          return "http://localhost:8088"
      }
    },
    chainId(state) {
      switch (state.network) {
        case "mainnet":
          return 1;
        case "testnet":
          return 2;
        case "devnet":
          return 4;
        default:
          return 0;
      }
    },
    networkTitleCase(state) {
      return state.network.slice(0,1).toUpperCase() + state.network.slice(1).toLowerCase();
    }
  },
  mutations: {
    setUserName (state, newUserName) {
      state.userName = newUserName;
    },
    setUserWalletAddress (state, newUserWalletAddress) {
      state.userWalletAddress = newUserWalletAddress;
    },
    setUserToken (state, newUserToken) {
      state.userToken = newUserToken;
    },
    setNetwork (state, newNetwork) {
      state.network = newNetwork;
    },
    setAuth (state, authData: AuthData) {
      state.userToken = authData.token;
      state.userName = authData.user_name;
      state.userRole = authData.user_role;
      state.userWalletAddress = authData.user_address;
    },
    clearAuth (state) {
      state.userToken = "";
      state.userName = "";
      state.userRole = "";
      state.userWalletAddress = "";

      localStorage.removeItem(state.network + "_auth_data");
    },
  },
  actions: {
    sendGetCall({ state, getters }, {uri, params}) {
      return new Promise((resolve, reject) => {
        axios.get(getters.endpointHost + uri, {
          params: params,
          headers: {"Authorization": "Bearer " + state.userToken},
        }).then((response) => {
          return resolve(response)
        }).catch((error) => {
          return reject(error)
        });
      });
    },
    sendPostCall({ state, getters }, {uri, body}) {
      return new Promise((resolve, reject) => {
        axios.post(getters.endpointHost + uri, body,{
          headers: {"Authorization": "Bearer " + state.userToken},
        }).then((response) => {
          return resolve(response)
        }).catch((error) => {
          return reject(error)
        });
      });
    },
  },
  modules: {}
})
